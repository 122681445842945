


































import { Vue, Component, Provide } from "vue-property-decorator";
import Nav from "@/components/header.vue";
import serve from "@/serve/mine";
@Component({
  components: {
    Nav,
  },
})
export default class Pocket extends Vue {
  page = 0
  listData = []
  balance: string | null | number = ""
  @Provide() add = "";
  @Provide() loading = false; //是否处于加载状态
  @Provide() finished = false; //是否已加载完所有数据
  @Provide() isLoading = false; //是否处于下拉刷新状态
  private recharge(): void {
    this.$router.push("/recharge");
  }
  created() {
    this.getIntergral();
    this.getIntergralList()
  }
  async getIntergral() {
    const res = await serve.getIntergral();
    if (res.data.code === 0) {
      if(res.data.data.balance){
        this.balance = res.data.data.balance
      }else {
        this.balance = 0
      }
    }
  }
  async getIntergralList (){
    const res = await serve.intergralRecord({page:this.page,size:10})
    if(res.data.code ===0){
      this.listData = this.listData.concat(res.data.data.content)
       this.loading = false;
      this.isLoading = false;
      if (res.data.data.totalElements <= this.listData.length) {
        this.finished = true;
      }else {
        this.finished = false
      }
    }
  }
  private onLoad(): void {
    // 上拉加载
    this.loading = true;
    this.getIntergralList();
    this.page ++
  }

  private onRefresh(): void {
    //下拉刷新
    this.listData = [];
    this.isLoading = true;
    this.page = 0;
    this.getIntergralList();
  }
  timeFormat(val: string): string{
    return new Date(parseInt(val) ).toLocaleString().replace(/:\d{1,2}$/,' '); 
  }
}
